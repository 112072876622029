import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import SEO from './seo';
import 'bootstrap/dist/css/bootstrap.css';
import './styles.css';

const Layout = ({ children }) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:400,500,400italic,300italic,300,500italic,700,700italic,900,900italic"
        rel="stylesheet"
        type="text/css"
      />
      <script src="https://use.fontawesome.com/bf835f2da2.js" />
    </Helmet>
    <SEO />
    {children}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
